function onSelectKeywords(){
    $('.js-keywords-quick-add-btn').trigger('click');
}

const ProfileQuickEdit = {
    selected_keywords_ids: [],
    selected_keywords_names: [],
    initialize: function (formName) {
        this.initializeEvents(formName);
    },
    initializeEvents: function (formName) {
        const keywordForm = $('form[name="'+formName+'"]');

        Autocompletes.keywords = new Autocomplete($('#'+formName+'_name'), '/profile/searchterm/researchkeywords/', onSelectKeywords);

        keywordForm.find('.ui-autocomplete-input').on('keydown', function (e) {
            if (e.keyCode == 13 || e.keyCode == 9) {
                e.preventDefault();
                const enteredWord = this.value.trim().toLowerCase();
                $('.ui-menu-item-wrapper').each(function(){
                    if($(this)[0].innerText.trim().toLowerCase() === enteredWord){
                        $(this).trigger('click'); // trigger if a word is in a dropdown
                    }
                });

                addResearchKeywords();
            }
        });

        // Keyword entry on blur
        keywordForm.find('.ui-autocomplete-input').on('blur', function (e){
            const enteredWord = this.value.trim().toLowerCase();
            $('.ui-menu-item-wrapper').each(function(){
                if($(this)[0].innerText.trim().toLowerCase() === enteredWord){
                    $(this).trigger('click'); // trigger if a word is in a dropdown and return
                    return false;
                }
            });
            // trigger click if it's a new word
            addResearchKeywords();
        });

        // Trigger enter press on click
        $('body').on('click', '.ui-autocomplete .ui-menu-item-wrapper', function(e){
            addResearchKeywords();
        });

        function addResearchKeywords()
        {
            const input_field = $('#'+formName+'_name');
            const keywordInputValue = input_field.val().trim();

            if (keywordInputValue.length === 0) {
                return false;
            }

            const formData = new FormData();
            $.each(keywordForm.serializeArray(), function (i, elem) {
                if (elem.name === 'research_keywords_brief_form[name]') {
                    formData.append(elem.name, keywordInputValue);
                } else {
                    formData.append(elem.name, elem.value);
                }
            });
            input_field.val('');

            $.ajax({
                type: 'POST',
                url: keywordForm.attr('action'),
                data: formData,
                dataType: 'JSON',
                contentType: false,
                processData: false,
                beforeSend: function () {
                    blockElement($('.keyword-add-wrap'));
                },
                success: function (data) {
                    if (data.success && data.state === 'end') {
                        $('.keyword-add-wrap').parent().before(data.responseView);
                        $('.js-keyword-input-form').hide();
                        $('.js-tag-keyword-add').show();
                    }
                },
                complete: function () {
                    reinitTooltip();
                    unblockElement($('.keyword-add-wrap'));
                }
            });
        }
    }
};
